@import '../../../common-assets/styles/utilities/functions';

.searchFieldsWrap {
  padding-top: pxToRem(30);
  margin-top: 0;
}

.form-control-label {
  padding-top: pxToRem(15);
}

.backtitle-search-content {
  padding: pxToRem(0, 24, 32);
}

.MuiSvgIcon-root {
  &.no-backtitles {
    display: block;
    margin: 0 auto;
    width: pxToRem(140);
    height: pxToRem(180);
  }
}

.no-results-msg {
  display: block;
  margin: 0px auto;
  padding: 40px;
  h3,
  p {
    display: block;
    text-align: center;
  }
  h3 {
    margin: 16px 0;
  }
  p {
    margin: 0px;
  }
}

.backtitle-header {
  position: relative;
  width: 100%;
}

.documentName {
  color: #0054a4;
  text-decoration: underline;
  cursor: pointer;
}

.p-b-25 {
  padding-bottom: pxToRem(25) !important;
}
